import { memo } from "react"
import { Button, Stack } from "@chakra-ui/react"
import { useCustomerAccount } from "@app/hooks/useCustomer"
import { useFormBuilder, FIELD_TYPES, ControlledInput, FunctionErrors } from "@app/hooks/useFormBuilder"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"

const AccountResetForm: React.FC = () => {
  const { handleResetSubmit, handleChange, data, loading, errors: functionErrors } = useCustomerAccount()
  const locales = useLocale(LOCALE_KEYS.ACCOUNT)

  const { fieldErrors, register, handleSubmit } = useFormBuilder({
    onSubmit: (data: any, event: any) => handleResetSubmit(event),
  })

  return (
    <Stack as="form" onSubmit={handleSubmit}>
      <ControlledInput type={FIELD_TYPES.PASSWORD} register={register} onChange={handleChange} fieldErrors={fieldErrors} data={data} />

      {locales?.accountResetPasswordSubmitBtn && (
        <Button type="submit" isDisabled={loading} isLoading={loading}>
          {locales?.accountResetPasswordSubmitBtn}
        </Button>
      )}

      <FunctionErrors errors={functionErrors} />
    </Stack>
  )
}

const MemoAccountResetForm = memo(AccountResetForm)
export { MemoAccountResetForm as AccountResetForm }
